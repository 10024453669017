<template>
  <CyMenu>
    <template #activator="{ on }">
      <div
        class="granularity-selector px-4 py-3"
        v-on="on">
        <div>
          <div class="granularity-selector__title mb-2">
            {{ $t('forms.granularity') }}
          </div>
          {{ _.find($static.options, ['value', value]).label }}
        </div>
        <v-icon>arrow_drop_down</v-icon>
      </div>
    </template>

    <v-list width="329">
      <v-list-item-group
        v-model="value"
        mandatory>
        <v-list-item
          v-for="{ value: optionValue, label } in $static.options"
          :key="optionValue"
          :value="optionValue"
          :disabled="!isValidOption(optionValue)">
          <v-list-item-title>
            {{ label }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </CyMenu>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CyCloudCostManagementSidebarGranularitySelector',
  computed: {
    ...mapState('organization/cloudCostManagement', {
      queryBody: (state) => state.queryBody,
    }),
    $static () {
      return {
        options: [
          {
            label: this.$t('forms.granularityOptions.daily'),
            value: 'day',
          },
          {
            label: this.$t('forms.granularityOptions.weekly'),
            value: 'week',
          },
          {
            label: this.$t('forms.granularityOptions.monthly'),
            value: 'month',
          },
        ],
      }
    },
    value: {
      get () {
        return _.get(this.queryBody, 'granularity', 'day')
      },
      set (value) {
        this.SET_QUERY_FILTER({ key: 'granularity', value })
        this.$emit('input')
      },
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    isValidOption (value) {
      const { begin, end } = this.queryBody

      if ($date.isBefore($date.addMonths(new Date(begin), 3), new Date(end))) {
        return value === 'month'
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  .granularity-selector {
    display: flex;
    justify-content: space-between;
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;
    background-color: cy-get-color("white");
    cursor: pointer;

    .v-icon {
      color: cy-get-color("black");
    }

    &__title {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;
    }
  }
</style>
