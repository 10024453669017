<template>
  <v-card
    :max-width="368"
    rounded="lg"
    outlined
    class="key-numbers">
    <v-card-title
      class="cy-headline"
      data-cy="key-numbers-card">
      <v-skeleton-loader
        v-if="loading"
        :max-height="14"
        :width="150"
        :max-width="334"
        type="text"/>
      <template v-else>
        {{ $t('cardTitle', [organizationName]) }}
      </template>
    </v-card-title>
    <v-card-text class="px-0 pt-0 pb-2">
      <v-row class="no-gutters">
        <v-col
          v-for="(value, key) in visibleStats"
          :key="key"
          cols="6"
          class="px-2">
          <v-skeleton-loader
            v-if="loading"
            :min-height="62"
            :max-height="62"
            :width="160"
            type="list-item-avatar-two-line"/>
          <component
            v-else
            :to="{
              name: { pipelines: 'pipelinesOverview' }[key] || key,
              params: { orgCanonical: organizationCanonical },
            }"
            :class="['key-numbers__item d-flex align-center rounded pa-2', {
              'cy-link': hasAccessTo(key),
            }]"
            :is="hasAccessTo(key) ? 'router-link' : 'span'">
            <v-avatar
              size="32"
              class="key-numbers__icon mr-4">
              <v-icon dense>
                {{ $static.statIcons[key] }}
              </v-icon>
            </v-avatar>
            <div>
              <div class="key-numbers__key font-weight-normal">
                {{ $tc(`${key}Countable`, value) }}
              </div>
              <div class="key-numbers__value">
                {{ value }}
              </div>
            </div>
          </component>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CyKeyNumbersCard',
  props: {
    organizationName: {
      type: String,
      required: true,
    },
    organizationCanonical: {
      type: String,
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    $static: () => ({
      statIcons: {
        projects: 'folder',
        pipelines: 'linear_scale',
        stacks: 'fa-cubes',
        credentials: 'mdi-key',
        members: 'person',
        teams: 'people',
      },
    }),
    visibleStats () {
      const { statIcons } = this.$static
      const statsWithIcons = _.pick(this.stats, _.keys(statIcons))
      return _.isEmpty(statsWithIcons)
        ? _.size(statIcons)
        : statsWithIcons
    },
  },
  methods: {
    hasAccessTo (key) {
      const permissionKeys = {
        stacks: 'ListServiceCatalogs',
        credentials: 'ListCredentials',
        members: 'GetOrgMembers',
      }
      const permissionKey = permissionKeys[key] || `Get${_.upperFirst(key)}`

      return this.$cycloid.permissions.canDisplay(permissionKey)
    },
  },
  i18n: {
    messages: {
      en: {
        cardTitle: '{0} key numbers',
      },
      es: {
        cardTitle: '{0} números clave',
      },
      fr: {
        cardTitle: '{0} chiffres clés',
      },
    },
  },
}

</script>

<style lang="scss" scoped>
$root: ".key-numbers";

#{$root} {
  align-self: baseline;
  border-color: cy-get-color("grey");
  color: cy-get-color("primary");

  &:hover {
    color: cy-get-color("primary");
  }

  &__icon {
    background-color: cy-get-color("primary", "light-5");

    .v-icon {
      color: cy-get-color("primary", "light-2");

      &.fa-cubes {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__key {
    color: cy-get-color("primary", "light-2");
    line-height: $line-height-sm;
  }

  &__value {
    color: cy-get-color("primary");
    font-size: $font-size-h3;
    line-height: $line-height-xs;
  }

  &__item {
    &:hover {
      background-color: cy-get-color("primary", "light-5");

      #{$root}__icon {
        background-color: cy-get-color("white");
      }
    }
  }
}
</style>
